
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { buttonTypes } from '@/constants';

import { Location } from '@/entities/Location';
import { Image } from '@/entities/images/Image';

@Component({
  computed: mapGetters([
    'location'
  ])
})
export default class BannerButton extends Vue{
  @Prop({ default: "" })
  value!: string;

  @Prop({ default: "" })
  asset!: string;

  @Prop({ default: () => null })
  setimage!: Function;

  type = "";
  types: string[] = [];

  image?: Image;
  images: Image[] = [];

  location!: Location;

  mounted() {
    this.types = buttonTypes;
    this.type = this.value.split(":")[0].trim();

    this.loadImages();
  }

  async loadImages() {
    this.$images.list().then((images) => {
      this.images = images;
      this.images = this.images.filter((image) => {
        if (image.category.toLowerCase() !== "banner") {
          return false;
        }

        if (image.location?.uuid !== this.location.uuid) {
          return false;
        }

        return true;
      });
    });
  }

  change() {
    this.$emit('input', `${this.type}` + (this.image != null ? `:${this.image.file_url}` : ''));
  }

  imageClicked(img: Image) {
    this.image = img;
    this.change();
  }
}
