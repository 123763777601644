
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// import store from '@/store';

import HomeButton from "@/components/buttons/HomeButton.vue";

import { Location } from '@/entities/Location';

@Component({
  computed: mapGetters([
    'location'
  ]),

  components: {
    HomeButton
  }
})
export default class CustomizeListView extends Vue{
  buttons: string[] = [];
  button = "";
  buttonURL = "";

  helpDialog = false;
  imageURL = "";

  location!: Location;

  mounted() {
    this.loadButtons();
  }

  openImageDialog(path: string) {
    this.imageURL = path;
    this.helpDialog = true;
  }

  @Watch("location")
  async loadButtons () {
    if (this.location.name === "N/A") return;

    this.buttons = this.location.home_buttons.split(",").map((element) => element.trim());
  }

  async save() {
    await this.$locations.edit(this.location.uuid, {
      home_buttons: this.buttons,
    });
    this.$store.dispatch('loadLocations');
  }
}
